import {
    A11y,
    Autoplay,
    Controller,
    EffectCards,
    EffectCoverflow,
    EffectCreative,
    EffectCube,
    EffectFade,
    EffectFlip,
    Keyboard,
    Manipulation,
    Navigation,
    Pagination,
    Swiper,
} from '../Lib/swiper/swiper.js';

Swiper.use([
    A11y,
    Autoplay,
    Controller,
    Pagination,
    Navigation,
    Keyboard,
    Manipulation,
    EffectCards,
    EffectCoverflow,
    EffectCreative,
    EffectCube,
    EffectFade,
    EffectFlip
]);

export {Swiper};
